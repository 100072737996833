import { Device } from "./Product.types";

export const searchProducts = (
    allDevices: Device[],
    searchFilter: {
        nameStartsWith?: string;
        allowedLineIds?: string[];
    }
) => {
    const { nameStartsWith, allowedLineIds } = searchFilter;
    const filteredProducts: Device[] = [];

    for (const device of allDevices) {
        const deviceName = device.product.name.toLowerCase();
        if (
            nameStartsWith &&
            !deviceName.startsWith(nameStartsWith.toLowerCase())
        ) {
            continue;
        }

        if (allowedLineIds && !allowedLineIds.includes(device.line.id)) {
            continue;
        }

        filteredProducts.push(device);
    }

    return filteredProducts;
};


export const getUniqueLineIdsFromProducts = (devices: Device[]): string[] => {
    const uniqueLineIds: { [name: string]: boolean } = {};

    for (const device of devices) {
        const lineId = device.line.id
        if (lineId) { uniqueLineIds[lineId] = true; }

    }

    return Object.keys(uniqueLineIds);
}