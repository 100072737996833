import { useEffect, useState } from 'react';
import { Device } from '../services/Product.types';

export const useDeviceData = (deviceId: string) => {
    const [deviceData, setDeviceData] = useState<Device>();
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string>();

    useEffect(() => {
        const fetchDeviceData = async () => {
            try {
                setLoading(true);
                setError(undefined);
                setDeviceData(undefined);

                const response = await fetch(
                    'https://static.ui.com/fingerprint/ui/public.json'
                );
                const data = await response.json();
                const device = data.devices.find(
                    (device: Device) => device.id === deviceId
                );

                if (device) {
                    setDeviceData(device);
                } else {
                    setError('Device not found.');
                }
            } catch (error) {
                setError('Error fetching device data. Please try again later.');
            } finally {
                setLoading(false);
            }
        };

        fetchDeviceData();
    }, [deviceId]);

    return { deviceData, loading, error };
};
