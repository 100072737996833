interface PaginationProps {
  currentPage: number;
  totalPages: number;
  navigateToPrevPage: () => void;
  navigateToNextPage: () => void;
  navigateToPage: (page: number) => void;
}

const Pagination = ({
  currentPage,
  totalPages,
  navigateToPrevPage,
  navigateToNextPage,
  navigateToPage,
}: PaginationProps) => {
  const getPageButtons = () => {
    const pageButtons = [];
    const pagesDisplayes = 3;
    const startPage = Math.max(currentPage - Math.floor(pagesDisplayes / 2), 1);
    const endPage = Math.min(startPage + pagesDisplayes - 1, totalPages);

    for (let page = startPage; page <= endPage; page++) {
      pageButtons.push(
        <button
          key={page}
          onClick={() => navigateToPage(page)}
          className={
            currentPage === page ? 'page-button active' : 'page-button'
          }
        >
          {page}
        </button>
      );
    }

    return pageButtons;
  };

  return (
    <div className="pagination">
      <button
        onClick={navigateToPrevPage}
        className={
          'pagination-button' + (currentPage === 1 ? ' hide-prev' : '')
        }
      >
        Previous
      </button>

      {totalPages !== 1 ? getPageButtons() : null}

      {currentPage !== totalPages && totalPages > 0 ? (
        <button
          onClick={navigateToNextPage}
          disabled={currentPage === totalPages}
          className="pagination-button"
        >
          Next
        </button>
      ) : null}
    </div>
  );
};

export default Pagination;
