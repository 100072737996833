import React, { useEffect, useRef, useState } from 'react';
import { getUniqueLineIdsFromProducts } from '../services/ProductSearch';
import DeviceFilters from './DeviceFilters';
import { Device } from '../services/Product.types';
import Dropdown from '../components/Dropdown';

interface FilterDropdownProps {
  devices: Device[];
  setActiveLineIds: (lineIds: string[]) => void;
  activeLineIds: string[];
}

const FilterDropdown = ({
  devices,
  setActiveLineIds,
  activeLineIds,
}: FilterDropdownProps) => {
  const [isFiltersOpen, setIsFiltersOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [allLineIds, setAllLineIds] = useState<string[]>([]);

  useEffect(() => {
    const lineIds = getUniqueLineIdsFromProducts(devices);
    setAllLineIds(lineIds);
  }, [devices]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target as Node)
    ) {
      setIsFiltersOpen(false);
    }
  };

  const toggleDropdown = () => {
    setIsFiltersOpen((prevState) => !prevState);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, []);

  return (
    <div className="dropdown" ref={dropdownRef}>
      <button className="btn-neutral" onClick={toggleDropdown}>
        Filter
      </button>
      {isFiltersOpen && (
        <Dropdown>
          <DeviceFilters
            setActiveLineIds={setActiveLineIds}
            allLineIds={allLineIds}
            activeLineIds={activeLineIds}
            setIsFiltersOpen={setIsFiltersOpen}
          />
        </Dropdown>
      )}
    </div>
  );
};

export default FilterDropdown;
