import { Dispatch, SetStateAction } from 'react';
import { Device } from '../services/Product.types';
import SearchInput from '../components/SearchInput';
import ThemeSwitcher from '../components/ThemeSwitcher';
import FilterDropdown from './FiltersDropdown';
import ViewModeToggles from './ViewModeToggles';

export type ViewMode = 'GRID' | 'LIST';

interface ToolbarProps {
  devices: Device[];
  setActiveLineIds: (lineIds: string[]) => void;
  activeLineIds: string[];
  searchValue: string;
  setSearchValue: (searchValue: string) => void;
  setViewMode: Dispatch<SetStateAction<ViewMode>>;
  viewMode: ViewMode;
}

const ToolbarPrimary = ({
  devices,
  setActiveLineIds,
  activeLineIds,
  searchValue,
  setSearchValue,
  viewMode,
  setViewMode,
}: ToolbarProps) => {
  return (
    <div className="toolbar">
      <div className="search-wrapper">
        <SearchInput
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
          onClear={() => setSearchValue('')}
        />
      </div>
      <div className="tools-wrapper">
        <ViewModeToggles viewMode={viewMode} onViewModeChange={setViewMode} />
        <FilterDropdown
          devices={devices}
          setActiveLineIds={setActiveLineIds}
          activeLineIds={activeLineIds}
        />
        <ThemeSwitcher />
      </div>
    </div>
  );
};

export default ToolbarPrimary;
