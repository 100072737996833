import { Device } from "../services/Product.types";

export const capitalizeFirstLetter = (text: string): string => {
    return text.charAt(0).toUpperCase() + text.slice(1);
}

export const generateKey = (pre: string, index: number) => {
    return `${index}${pre}_${new Date().getTime()}`;
}

export const iconUrlForDevice = (device: Device, preferredSize: number) => {
    let closestFoundSize = Number.MAX_SAFE_INTEGER;
    for (const [size] of device.icon.resolutions) {
        if (Math.abs(size - preferredSize) < closestFoundSize) {
            closestFoundSize = size;
        }
    }
    return `https://static.ui.com/fingerprint/ui/icons/${device.icon.id}_${closestFoundSize}x${closestFoundSize}.png`
}