import React from 'react';
import CheckboxComponent from '../components/Checkbox';
import { ReactComponent as Cross } from '../assets/icons/Cross.svg';

interface DeviceFiltersProps {
  setActiveLineIds: (lineIds: string[]) => void;
  allLineIds: string[];
  activeLineIds: string[];
  setIsFiltersOpen: (isOpen: boolean) => void;
}

const DeviceFilters = ({
  setActiveLineIds,
  allLineIds,
  activeLineIds,
  setIsFiltersOpen,
}: DeviceFiltersProps) => {
  const handleCheckboxChange = (id: string, isChecked: boolean) => {
    if (isChecked) {
      setActiveLineIds([...activeLineIds, id]);
    } else {
      setActiveLineIds(activeLineIds.filter((itemId) => itemId !== id));
    }
  };

  const closeDropdown = () => {
    setIsFiltersOpen(false);
  };

  return (
    <>
      <div className="dropdown-header">
        <p>Filter</p>
        <i onClick={closeDropdown}>
          <Cross />
        </i>
      </div>
      <div className="dropdown-body">
        <h4>Products</h4>
        <div className="products-wrapper">
          {allLineIds.map((id: string) => {
            return (
              <CheckboxComponent
                key={id}
                labelText={id}
                checked={activeLineIds.includes(id)}
                onChange={(event) =>
                  handleCheckboxChange(id, event.target.checked)
                }
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DeviceFilters;
