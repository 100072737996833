import { ReactComponent as List } from '../assets/icons/List.svg';
import { ReactComponent as ListFill } from '../assets/icons/ListFill.svg';
import { ReactComponent as Grid } from '../assets/icons/Grid.svg';
import { ReactComponent as GridFill } from '../assets/icons/GridFill.svg';
import { Dispatch, SetStateAction } from 'react';
import { ViewMode } from './ToolbarPrimary';

interface ViewModeTogglesProps {
  onViewModeChange: Dispatch<SetStateAction<ViewMode>>;
  viewMode: string;
}

const ViewModeToggles = ({
  viewMode,
  onViewModeChange,
}: ViewModeTogglesProps) => {
  return (
    <div className="view-mode-toggles">
      <button
        className={`btn-icon ${viewMode === 'LIST' ? 'active' : ''}`}
        onClick={() => onViewModeChange('LIST')}
      >
        <i>{viewMode === 'LIST' ? <ListFill /> : <List />}</i>
      </button>
      <button
        className={`btn-icon ${viewMode === 'GRID' ? 'active' : ''}`}
        onClick={() => onViewModeChange('GRID')}
      >
        <i>{viewMode === 'GRID' ? <GridFill /> : <Grid />}</i>
      </button>
    </div>
  );
};

export default ViewModeToggles;
