import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './theme/theme.css';
import './styles/main.scss';
import HomePage from './pages/HomePage';
import { useColorMode } from './theme/colorModeContext';
import { RoutedDetailPage } from './pages/DetailPage';

function App() {
  const { colorMode } = useColorMode();

  return (
    <div className={'App ' + colorMode} data-testid="app-content">
      <Router>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/device/:deviceId" element={<RoutedDetailPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
