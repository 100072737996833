import { ReactComponent as Dark } from '../assets/icons/Dark.svg';
import { ReactComponent as Light } from '../assets/icons/Light.svg';
import { useColorMode } from '../theme/colorModeContext';

const ThemeSwitcher = () => {
  const { colorMode, setColorMode } = useColorMode();

  return (
    <button
      className="btn-icon"
      onClick={() => setColorMode(colorMode === 'dark' ? 'light' : 'dark')}
    >
      <i>
        {colorMode === 'dark' ? (
          <Light className="icon md" />
        ) : (
          <Dark className="icon md" />
        )}
      </i>
    </button>
  );
};

export default ThemeSwitcher;
