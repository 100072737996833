import logo from '../assets/images/Ubiquiti_logo.png';

const Navigation = () => {
  return (
    <div className="navigation">
      <div className="logo-wrapper">
        <img src={logo} alt="Ubiquiti_logo" />
        <h3>Devices</h3>
      </div>

      <p className="author">Author/Marta Lewandowska</p>
    </div>
  );
};

export default Navigation;
