import { MouseEventHandler } from 'react';

interface CardProps {
  imageSrc: string;
  title: string;
  line: string;
  onClick: MouseEventHandler<HTMLDivElement>;
}

const Card = ({ imageSrc, title, line, onClick }: CardProps) => {
  return (
    <div className="card" onClick={onClick}>
      <div className="img-wrapper">
        <img src={imageSrc} alt={title} />
      </div>
      <div className="description-wrapper">
        <h4>{title}</h4>
        <span>{line}</span>
      </div>
    </div>
  );
};

export default Card;
