import { useParams } from 'react-router-dom';
import Navigation from '../layouts/Navigation';
import ToolbaSecondary from '../layouts/ToolbarSecondary';
import DevicePreview from '../layouts/DevicePreview';
import { useDeviceData } from '../hooks/useDeviceData';
import LoadingSpinner from '../components/LoadingSpinner';
import { useState } from 'react';

export const RoutedDetailPage = () => {
  const { deviceId } = useParams<{ deviceId: string }>();

  if (!deviceId) {
    return <></>;
  }

  return <DetailPage deviceId={deviceId} />;
};

export const DetailPage = ({ deviceId }: { deviceId: string }) => {
  const { deviceData, loading, error } = useDeviceData(deviceId);
  const [copySuccess, setCopySuccess] = useState(false);

  const copyLinkToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(window.location.href);
      setCopySuccess(true);
      setTimeout(() => setCopySuccess(false), 2000); // Reset success message after 2 seconds
    } catch (error) {
      console.error('Error copying link:', error);
    }
  };

  return (
    <div className="detail-page">
      <Navigation />
      <ToolbaSecondary devicesName={deviceData?.product.name} />
      <button onClick={copyLinkToClipboard} className="btn-neutral">
        {copySuccess ? 'Link Copied!' : 'Copy Link to device'}
      </button>
      {loading && <LoadingSpinner />}
      {error && <p>{error}</p>}
      {deviceData && !loading && !error && (
        <DevicePreview deviceData={deviceData} />
      )}
    </div>
  );
};
