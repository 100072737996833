import { ReactNode } from 'react';

interface DropdownProps {
  children: ReactNode;
}

const Dropdown = ({ children }: DropdownProps) => {
  return <div className="dropdown-content">{children}</div>;
};

export default Dropdown;
