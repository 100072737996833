import { ChangeEvent } from 'react';
import { ReactComponent as Search } from '../assets/icons/Search.svg';
import { ReactComponent as Cross } from '../assets/icons/Cross.svg';
import Input from './Input';

interface SearchInputProps {
  value: string;
  onChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onClear: () => void;
}

const SearchInput = ({ value, onChange, onClear }: SearchInputProps) => {
  return (
    <Input
      prefix={<Search />}
      suffix={value ? <Cross onClick={onClear} /> : null}
      placeholder="Search"
      value={value}
      onChange={onChange}
    />
  );
};

export default SearchInput;
