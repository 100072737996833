import {
  capitalizeFirstLetter,
  generateKey,
  iconUrlForDevice,
} from '../helpers/helpers';
import { Device } from '../services/Product.types';

interface ProductListProps {
  onClick: (deviceId: string) => void;
  devices: Device[];
  totalDevices: number;
}

const ProductsList = ({ onClick, devices, totalDevices }: ProductListProps) => {
  return (
    <div className="products-list-wrapper" data-testid="product-list">
      <table className="table">
        <thead>
          <tr>
            <th>
              <span>{totalDevices} devices</span>
            </th>
            <th>NAME</th>
            <th>PRODUCT LINE</th>
          </tr>
        </thead>
        <tbody>
          {devices.map((device, index) => {
            const { id, product, line } = device;
            return (
              <tr
                data-testid={'product-list-item-' + id}
                key={generateKey(id, index)}
                onClick={() => onClick(id)}
              >
                <td>
                  <img
                    src={iconUrlForDevice(device, 26)}
                    alt={product.name}
                    width="100"
                    height="26"
                  />
                </td>
                <td>{capitalizeFirstLetter(product.name)}</td>
                <td>{capitalizeFirstLetter(line.name)}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ProductsList;
