import Card from '../components/Card';
import { Device } from '../services/Product.types';

interface ProductGridProps {
  onClick: (deviceId: string) => void;
  devices: Device[];
  totalDevices: number;
}

const ProductsGrid = ({ onClick, devices, totalDevices }: ProductGridProps) => {
  return (
    <div className="products-grid-wrapper">
      <span>{totalDevices + ' devices'}</span>
      <div className="cards-wrapper">
        {devices.map((device) => (
          <Card
            key={device.id}
            imageSrc={`https://static.ui.com/fingerprint/ui/icons/${device.icon.id}_257x257.png`}
            title={device.product.name}
            line={device.line.name}
            onClick={() => onClick(device.id)}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductsGrid;
