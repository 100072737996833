import { useEffect, useState } from 'react';
import { Device } from '../services/Product.types';

export const useFetchDevices = () => {
    const [devices, setDevices] = useState<Device[]>([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await fetch(
                    'https://static.ui.com/fingerprint/ui/public.json'
                );
                const result = await response.json();
                setDevices(result.devices);
            } catch (error) {
                setError('Error fetching data. Please try again later.');
            } finally {
                setLoading(false);
            }

        };

        fetchData();
    }, []);

    return { devices, loading, error };
};
