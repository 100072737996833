import { ChangeEventHandler, ReactNode } from 'react';

interface InputProps {
  prefix: ReactNode | string;
  suffix: ReactNode | string;
  placeholder: string;
  value: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
}

const TextInput = ({
  prefix,
  suffix,
  placeholder,
  value,
  onChange,
}: InputProps) => {
  return (
    <div className="text-input-wrapper">
      {prefix && <span className="text-input-prefix">{prefix}</span>}
      <input
        type="text"
        className="text-input"
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      {suffix && <span className="text-input-suffix">{suffix}</span>}
    </div>
  );
};

export default TextInput;
