import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Navigation from '../layouts/Navigation';
import Pagination from '../layouts/Pagination';
import { searchProducts } from '../services/ProductSearch';
import ProductsGrid from '../layouts/ProductsGrid';
import ProductsList from '../layouts/ProducstList';
import ToolbarPrimary, { ViewMode } from '../layouts/ToolbarPrimary';
import { useFetchDevices } from '../hooks/useFetchDevices';
import LoadingSpinner from '../components/LoadingSpinner';

const ITEMS_PER_PAGE = 15;

export const HomePage = () => {
  const { devices, loading, error } = useFetchDevices();
  const [activeLineIds, setActiveLineIds] = useState<string[]>([]);
  const [viewMode, setViewMode] = useState<ViewMode>('LIST');
  const [filteredDevices, setFilteredDevices] = useState(devices);
  const [searchValue, setSearchValue] = useState('');
  const [currentPage, setCurrentPage] = useState(1);

  const navigate = useNavigate();
  useEffect(() => {
    const filteredDevices = searchProducts(devices, {
      nameStartsWith: searchValue,
      allowedLineIds: activeLineIds.length === 0 ? undefined : activeLineIds,
    });
    setFilteredDevices(filteredDevices);
    setCurrentPage(1);
  }, [activeLineIds, devices, searchValue]);

  useEffect(() => {
    setActiveLineIds([]);
  }, [devices]);

  const navigateToDevicePage = (deviceId: string) => {
    const devicePagePath = `/device/${deviceId}`;
    navigate(devicePagePath);
  };

  const paginatedDevices = filteredDevices.slice(
    (currentPage - 1) * ITEMS_PER_PAGE,
    currentPage * ITEMS_PER_PAGE
  );

  const totalPages = Math.ceil(filteredDevices.length / ITEMS_PER_PAGE);

  const navigateToPage = (page: number) => {
    setCurrentPage(page);
  };

  const navigateToNextPage = () => {
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  const navigateToPrevPage = () => {
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  return (
    <div className="home-page">
      <Navigation />
      <ToolbarPrimary
        devices={devices}
        setActiveLineIds={setActiveLineIds}
        activeLineIds={activeLineIds}
        searchValue={searchValue}
        setSearchValue={setSearchValue}
        setViewMode={setViewMode}
        viewMode={viewMode}
      />
      {loading && <LoadingSpinner />}
      {error && <p>Error fetching data: {error}</p>}
      {!loading && !error && viewMode === 'GRID' ? (
        <ProductsGrid
          onClick={navigateToDevicePage}
          devices={paginatedDevices}
          totalDevices={filteredDevices.length}
        />
      ) : (
        <ProductsList
          devices={paginatedDevices}
          onClick={navigateToDevicePage}
          totalDevices={filteredDevices.length}
        />
      )}

      {!loading && !error && (
        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          navigateToPrevPage={navigateToPrevPage}
          navigateToNextPage={navigateToNextPage}
          navigateToPage={navigateToPage}
        />
      )}
    </div>
  );
};

export default HomePage;
