import { iconUrlForDevice } from '../helpers/helpers';
import { Device } from '../services/Product.types';

interface DevicePreviewProps {
  deviceData: Device;
}

interface DeviceDetails {
  name: string;
  value: string | number;
}

const DevicePreview = ({ deviceData }: DevicePreviewProps) => {
  const deviceDetails: DeviceDetails[] = [
    { name: 'Product Line', value: deviceData.line.name },
    { name: 'ID', value: deviceData.id },
    { name: 'Name', value: deviceData.product.name },
    { name: 'Short name', value: deviceData.shortnames[0] },
  ];

  return (
    <div className="device-preview">
      <div className="device-body-wrapper">
        <div className="device-wrapper">
          <img
            src={iconUrlForDevice(deviceData, 256)}
            alt={`${deviceData.product.name}`}
          />
          <table className="table">
            <tbody>
              {deviceDetails.map((detail) => (
                <tr key={detail.name}>
                  <th>{detail.name}</th>
                  <td>{detail.value}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default DevicePreview;
