import { useNavigate } from 'react-router-dom';
import { ReactComponent as BackArrow } from '../assets/icons/Back.svg';
import ThemeSwitcher from '../components/ThemeSwitcher';
import { capitalizeFirstLetter } from '../helpers/helpers';

interface ToolbarProps {
  devicesName?: string;
}

const ToolbaSecondary = ({ devicesName }: ToolbarProps) => {
  const navigate = useNavigate();

  const handleBackClick = () => {
    navigate('/');
  };

  return (
    <div className="toolbar">
      <button className="btn-icon navigation-icon" onClick={handleBackClick}>
        <i>
          <BackArrow />
        </i>
      </button>

      <h4 className="toolbar-name">
        {devicesName ? capitalizeFirstLetter(devicesName) : ''}
      </h4>
      <ThemeSwitcher />
    </div>
  );
};

export default ToolbaSecondary;
