import React, { ChangeEventHandler } from 'react';
import { ReactComponent as Checked } from '../assets/icons/Checked.svg';
import { capitalizeFirstLetter } from '../helpers/helpers';

interface CheckboxProps {
  labelText: string;
  checked: boolean;
  onChange: ChangeEventHandler<HTMLInputElement>;
}

const CheckboxComponent = ({ labelText, checked, onChange }: CheckboxProps) => {
  return (
    <div className="checkbox">
      <input
        type="checkbox"
        id={labelText}
        checked={checked}
        onChange={onChange}
      />
      <label htmlFor={labelText}>
        {capitalizeFirstLetter(labelText)} {checked && <Checked />}
      </label>
    </div>
  );
};

export default CheckboxComponent;
